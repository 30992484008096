.error{
    color:#ff0000;
}

.errorText{
    color: white !important;
}

.color.color {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 10px 10px 5px;
    border-bottom: 1px solid #ebebeb!important;
    border: none;
    box-sizing: border-box;
    margin-top: 22px;
    box-shadow: none;
    font-family: Open Sans,sans-serif;
  }

  .zero-position {
    position: absolute;
    left: 45%;
    top: 69%;
    font-size: 32px !important;
}

.zero-position-success {
    position: absolute;
    left: 45%;
    top: 71%;
    font-size: 32px !important;
}


.ul-r li:nth-child(2) img{
    width:4vw;
}




.helppagetimer {
    height: 32px;
    position: relative;
    left: 138px;
    z-index: 10000;
    top: 12px;
    width: 100px;
    background: #f1f3f4;
}